import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Image from "next/image";
import React, { useRef } from "react";
import AnimatedCapsule from "../../common/ui/AnimatedCapsule";

const USPHome = () => {
    const uspRef = useRef(null);
    useGSAP(
        () => {
            const pad = gsap.matchMedia();
            pad.add("(min-width: 992px)", () => {
                gsap.to(uspRef.current, {
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: uspRef.current,
                        start: "top 60%",
                        end: "top 60%",
                        toggleActions: "play none none reverse"
                    }
                });
            });
        },
        { scope: useRef }
    );
    const data = [
        {
            id: 1,
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/profit-share-usp.svg",
            title: "15% Profit Share in Challenge Phase",
            description:
                "FundedNext is the only firm that offers a 15% profit share from the Challenge Phase profits, so you can start earning immediately."
        },
        {
            id: 2,
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/trade.svg",
            title: "Trade with MT4, MT5  & cTrader ",
            description:
                "At FundedNext, our traders have the opportunity to trade with a platform of their choosing - MetaTrader 4, MetaTrader 5, and cTrader. This ensures you have the best trading experience."
        },
        {
            id: 1,
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/limit.svg",
            title: "No Time Limit",
            description:
                "At FundedNext, traders can enjoy no time limits to stay stress-free during their Challenges."
        },
        {
            id: 2,
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/balance.svg",
            title: "Balance-Based Drawdown",
            description:
                "FundedNext offers a balance-based drawdown, ensuring traders can hold their trades tension-free."
        },
        {
            id: 1,
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/best-trading-con.svg",
            title: "Best Trading Conditions",
            description:
                "Our trading server ‘FundedNext’ ensures faster executions, super raw spreads, and the lowest commissions."
        },
        {
            id: 2,
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/news-trade.svg",
            title: "News Trading",
            description:
                "FundedNext allows news trading so you can quickly make profits by utilizing big market movements when a high-impact news is announced."
        },
        {
            id: 1,
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/monthly-com.svg",
            title: "Monthly Competition",
            description:
                "FundedNext hosts free monthly competitions where you can compete for exclusive rewards."
        }
    ];

    const SingleUSPRender = (icon, title, description, index) => {
        return (
            <div
                className="h-auto w-[260px] max-w-[290px]    rounded-[20px] p-[24px]  lg:p-[30px] xl:w-full"
                style={{
                    background: "rgba(255, 255, 255, 0.08)"
                }}
                key={index}
            >
                <Image
                    src={icon}
                    alt=""
                    className="h-[36px] w-[36px]"
                    height={36}
                    width={36}
                />
                <p className=" py-[10px] text-lg font-medium leading-[26px] text-white">
                    {title}
                </p>
                <p className="pt-[4px] font-Inter text-sm leading-[24px] text-[rgba(255,255,255,0.60)]">
                    {description}
                </p>
            </div>
        );
    };

    const RenderLargeScreenUSP = (id) => {
        return (
            <>
                <div
                    className={`flex  w-1/2 flex-wrap gap-y-[18px] ${id === 1 ? "pr-[18px]" : "pl-[18]"} `}
                >
                    {data?.map((item, idx) => {
                        if (item?.id === id)
                            return SingleUSPRender(
                                item?.icon,
                                item?.title,
                                item?.description,
                                idx
                            );
                    })}
                </div>
            </>
        );
    };

    const RenderSmallScreenUSP = () => {
        return (
            <div className="flex gap-x-[12px]  xl:hidden">
                {data?.map((item, idx) => {
                    return SingleUSPRender(
                        item?.icon,
                        item?.title,
                        item?.description,
                        idx
                    );
                })}
            </div>
        );
    };

    return (
        <div
            ref={uspRef}
            className="-mt-[60px] sm:mt-0 pad:translate-y-[100px]"
        >
            <div className="px-5 md:px-[60px] xl:px-0">
                <AnimatedCapsule text="Key Highlights" />
                <p className="relative mt-4 text-[24px] font-[500] leading-[150%] text-white xl:text-[30px]">
                    Maximize Your Trading Success with FundedNext. Trade up to{" "}
                    <span className="font-bold  text-[#11ACFF]">$300,000</span>{" "}
                    <br className="hidden xl:block" />
                    on a FundedNext Account and earn{" "}
                    <span className="font-bold text-[#8A42FF]">
                        maximum profit.
                    </span>
                </p>
            </div>
            <div className="hidden flex-wrap items-start pt-[60px] xl:flex">
                {RenderLargeScreenUSP(1)}
                {RenderLargeScreenUSP(2)}

                {/* <div className="mt-[60px] xl:block">
                    <div className="mb-5 text-[32px] font-medium text-white">
                        Latest Offers
                    </div>

                    <div>
                        <OfferCard />
                    </div>
                </div> */}
            </div>

            <div className="hide-scroll-bar x;:hidden flex  overflow-scroll px-5 pt-6 md:px-[60px]">
                {RenderSmallScreenUSP()}
            </div>

            {/* <div className="container-v2 mt-[60px] xl:hidden">
                <div className="mb-5 text-[32px] font-medium text-white">
                    Latest Offers
                </div>
                <div>
                    <OfferCardMobile />
                </div>
            </div> */}
        </div>
    );
};

export default USPHome;