import GradientStar from "../components/SVGIcons/GradientStar";
import GradientQuote from "../components/SVGIcons/GradientQuote";

const CelebrityData = [
    {
        id: 0,
        name: "Colin Munro",
        quote: "Join FundedNext and gain BIG, just like I hit BIG sixes!",
        designation: "Cricketer",
        image: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/colin.png",
        video: "huOXFTp-Oo4",
        icon: <GradientStar />
    },
    {
        id: 1,
        name: "Emi Martinez",
        quote: "Passion takes you to glory.",
        designation: "Footballer",
        image: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/martinez.png",
        video: "u6LHE75w2n8",
        icon: <GradientQuote />
    },
    {
        id: 2,
        name: "Chris Gayle",
        quote: "Never give up, it's something you should look into.",
        designation: "Cricketer",
        image: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/christ_gayle.png",
        video: "HTF8IwbUADE",
        icon: <GradientStar />
    }
];

export default CelebrityData;