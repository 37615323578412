const testyData = [
    {
        id: 0,
        type: "basic",
        name: "Mathilde Girard",
        bg: "white",
        title_color: "black",
        text_color: "#575E67",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/1.svg",
        comment:
            "The dashboard is very intuitive and user-friendly and the support team is very responsive and helpful. It's even better now that we can connect the account directly with Tradingview. Hope fundednext will continue to do well in the future and keep up the good work."
    },
    {
        id: 1,
        type: "basic",
        name: "Etienne Moreau",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/14.svg",
        comment:
            "FundedNext rocks! Today, Amy from their customer service team helped me with inactivity issues. She clarified rules, offered solutions, and even wished me a great day off! Awesome experience!"
    },
    {
        id: 2,
        type: "basic",
        name: "Rohan Porter",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/15.svg",
        comment:
            "Fundednext customer service is fantastic! Today, I spoke to Amy from FundedNext customer service about inactivity issues. I want to take a break once I hit 5% within the month on both my challenge and live accounts. Amy provided a perfect response, clarifying the rules and offering a solution. She even wished me a great break day! What a pleasant experience!"
    },
    {
        id: 3,
        type: "basic",
        name: "Madeleine",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/2.svg",
        comment:
            "FundedNext rocks! Today, Amy from their customer service team helped me with inactivity issues. She clarified rules, offered solutions, and even wished me a great day off! Awesome experience!"
    },
    {
        id: 4,
        type: "basic",
        name: "Richard Arwin",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/22.svg",
        comment:
            "The 15% profit share from the challenge phase is the game changer for me. Nothing like it in the entire industry! FundedNext is doing things right."
    },
    {
        id: 5,
        type: "basic",
        name: "Matteo Hernandez",
        bg: "white",
        title_color: "black",
        text_color: "#575E67",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/13.svg",
        comment:
            "FundedNext transformed my finances! Before, minimal profits; now, significant progress. A thousand blessings for this opportunity!"
    },
    {
        id: 6,
        type: "basic",
        name: "Raj Patel",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/21.svg",
        comment:
            "I've been with several prop firms, but FundedNext's commitment to trader success is top-notch. The 24-hour payout guarantee is real!"
    },
    {
        id: 7,
        type: "basic",
        name: "Samuel",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/19.svg",
        comment:
            "The path to financial freedom Very good evening, thank you for changing my life before belonging to the FundedNext family, I did not have the money to share a funding account, with my personal account the profits were minimal, now I am making a lot of progress to achieve my financial freedom. Funded Next is giving me a great opportunity to achieve my goals faster, and to many people who do not have sufficient resources, a thousand blessings THANK YOU for the great opportunity."
    },
    {
        id: 8,
        type: "small-chart",
        bold_text: "8.1M",
        comment: "Total visitors this month"
    },
    {
        id: 9,
        type: "basic",
        name: "Tunde Afolabi",
        bg: "#8A42FF",
        title_color: "white",
        text_color: "#FFFFFFBF",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/4.svg",
        comment:
            "One of the best prop firms! Being able to trade on MT4/MT5, is so important for me as I personally prefer to trade on MT rather than other platforms. The 24-hour guaranteed payout is real. They processed my recent payout within 12 hours They also rewarded 5% of the profit made during the challenge stages. This tells me that this prop firm values its users' time and effort. Truly appreciate it, thank you! Great Prop Firm!"
    },
    {
        id: 10,
        type: "basic",
        name: "Sophie Dubois",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/12.svg",
        comment:
            "FundedNext is the best! Completing phases 1 and 2 was great. Now on a long journey with anchored trader blessings. Keep improving, 5 stars!"
    },
    {
        id: 11,
        type: "basic",
        name: "Ronnie Kaur",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/16.svg",
        comment:
            "FundedNext shines! Completing phases 1 and 2 has been incredible, and now I'm embarking on a promising journey ahead. The dedication to improving services and the support from anchored traders are truly appreciated. I'm delighted to award FundedNext with 5 stars for their outstanding commitment to excellence!"
    },

    {
        id: 12,
        type: "basic",
        name: "Lucien Fornier",
        bg: "#4CEAFF",
        title_color: "black",
        text_color: "#000000BF",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/5.svg",
        comment:
            "Fantastic assistance! Technical support swiftly resolved an account issue in under 36 hours. They were attentive, constantly updated me, and showed great reliability. Highly recommended!"
    },
    {
        id: 13,
        type: "basic",
        name: "Tony Fergurson",
        bg: "#FFE175",
        title_color: "black",
        text_color: "#000000BF",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/8.svg",
        comment:
            "FundedNext changed the game for me with their 15% profit share right from the challenge phase—highly recommend!"
    },
    {
        id: 14,
        type: "basic",
        name: "Amrita Singh",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/18.svg",
        comment:
            "Received my initial payout last week, and to my surprise, it arrived within a mere 7 hours! Working with FundedNext has truly been a pleasant experience from start to finish."
    },
    {
        id: 15,
        type: "basic",
        name: "Sofia Conte",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/3.svg",
        comment:
            "FundedNext shines! Completed phases 1 and 2, now on a journey. Keep up the great work, 5 stars from me!"
    },
    {
        id: 16,
        type: "big-chart",
        bold_text: "4.5hrs",
        comment: "Avg. Payout Time"
    },
    {
        id: 17,
        type: "basic",
        name: "Chinedu Eze",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/6.svg",
        comment:
            "I made payouts after payouts from multiple accounts and every time I received my money in just 6-7 hours maximum. Very good and legit company. And on stellar 2-step accounts, they have easy rules. And the support is great. They answer really fast. Aiden from Live Support is very good. Thank you very much for helping me to merge my accounts."
    },
    {
        id: 18,
        type: "basic",
        name: "Oliver",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/7.svg",
        comment:
            "With FundedNext, I feel like a valued trader. Their raw spreads and low commissions make a huge difference in my trading results."
    },

    {
        id: 19,
        type: "basic",
        name: "Joshua Nadiri",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/9.svg",
        comment:
            "The 15% profit share from the start and the transparent conditions make FundedNext stand out in the crowded prop trading space."
    },
    {
        id: 20,
        type: "basic",
        name: "Sang Nguyen",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/10.svg",
        comment:
            "I got to win an account by playing a game! How cool is that! The mods are totally helpful, supportive and the overall community is so cool. I have a very good feeling about FundedNext. Best of luck for them!"
    },
    {
        id: 21,
        type: "basic",
        name: "Disha Patel",
        bg: "white",
        title_color: "black",
        text_color: "#575E67",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/17.svg",
        comment:
            "One of the best funding firms I have come across, with quick customer service and in a few minutes you get the account you purchased, I recommend traders to try this firm."
    },
    {
        id: 22,
        type: "basic",
        name: "Emily Mathews",
        img_url:
            "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/testimonial-images/11.svg",
        comment:
            "5-star for payout as promised including evaluation fee I had a different experience in the past, but anyway, we live now and I trade very accurately, strictly based on FN rules. I got some emails regarding different IP locations, but after proof from my side as a perfectionist all correct the first payout went fast including a 125% refund of the evaluation fee (part of the offer I bought) and 80% of the profit split. No reason for not giving 5 stars. The next payout is coming soon, not expecting any unexpected issues to keep up my rating."
    }
];

export default testyData;
