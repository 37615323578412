import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useRef } from "react";
import testyData from "../../../../data/testimonialData";
import AnimatedCapsule from "../../common/ui/AnimatedCapsule";
import BasicCard from "../../common/ui/BasicCard";
import BigChartData from "../../common/ui/BigChartCard";
import NumberCard from "../../common/ui/NumberCard";
import SmallChartCard from "../../common/ui/SmallChartCard";

const Testimonialv2 = () => {
    // This function is used to split the data into chunks of arrays
    // Example: Original Array = [a, b, c, d, e, f, g, h, i,j]
    // If we want to split the array into 4 columns, the output will be:
    // Output: [[a, b, c], [d, e, f], [g, h, i], [j]]

    const splitIntoChunks = (arr, colNumber) => {
        const chunksItemsNumber = Math.ceil(arr.length / colNumber);
        const chunksArr = [];
        for (let i = 0; i < colNumber; i++) {
            chunksArr.push(
                arr.slice(i * chunksItemsNumber, (i + 1) * chunksItemsNumber)
            );
        }
        return chunksArr;
    };

    const chunks = splitIntoChunks(testyData, 6);
    const container = useRef(null);
    const translateArrLg = [-50, 100, 250, 200, 100, -100];
    const translateArrTab = [-50, 80, 160, 100, -100, 0];
    const translateArrPad = [0, -40, 40, 80, -50, 0];
    const translateArrMobile = [0, 0, -20, 20, 0, 0];

    const getTranslateArr = (size) => {
        if (size <= 1280 && size > 992) {
            return translateArrTab;
        } else if (size <= 992 && size > 768) {
            return translateArrPad;
        } else if (size <= 768) {
            return translateArrMobile;
        } else {
            return translateArrLg;
        }
    };

    useGSAP(
        () => {
            setTimeout(() => {
                const allCards = gsap.utils.toArray(".card-colums");
                allCards.forEach((card, index) => {
                    gsap.to(card, {
                        y: getTranslateArr(container.current.offsetWidth)[
                            index
                        ],
                        scrollTrigger: {
                            trigger: ".card-wrapper",
                            start: "top 90%",
                            end: "top 0%",
                            scrub: index % 2 === 0 ? 1 : 2
                        }
                    });
                });
            }, 300);
        },
        { scope: container }
    );

    return (
        <section
            className="relative overflow-hidden bg-black px-5 py-[60px] text-white lg:py-[120px]"
            ref={container}
        >
            <div className="mb-4 flex justify-center">
                <AnimatedCapsule text="Trader Feedback & Analysis" />
            </div>
            <h2 className="title-50 text-center font-Rebond font-bold">
                Our Traders L
                <span className="text-[18px] sm:text-[30px] xl:text-[35px]">
                    ❤️
                </span>
                ve Us
            </h2>
            <div className="card-wrapper mt-10 grid grid-cols-2 gap-3 mobile:grid-cols-4 pad:grid-cols-5 tab:grid-cols-6 laptop:gap-5">
                {chunks.map((chunk, index) => {
                    return (
                        <div
                            key={"testimonial-column" + index}
                            className={`card-colums space-y-3 laptop:space-y-5 ${index === 5 && "hidden tab:block"} ${index === 0 && "hidden pad:block"} ${index === 1 && "hidden mobile:block"} ${index === 4 && "hidden mobile:block"}`}
                        >
                            {chunk.map((item, index) => {
                                return item.type === "basic" ? (
                                    <BasicCard item={item} key={item.id} />
                                ) : item.type === "small-chart" ? (
                                    <SmallChartCard item={item} key={item.id} />
                                ) : item.type === "number" ? (
                                    <NumberCard item={item} key={item.id} />
                                ) : (
                                    item.type === "big-chart" && (
                                        <BigChartData
                                            item={item}
                                            key={item.id}
                                        />
                                    )
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div
                className="absolute bottom-0 left-0 h-[20%] w-full mobile:h-[863px]"
                style={{
                    background:
                        "linear-gradient(0deg, #000000, rgba(7, 3, 19, 0.70) 61.37%, #07031300)"
                }}
            ></div>
        </section>
    );
};

export default Testimonialv2;
